import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Masonry from 'react-masonry-css';
import Layout from '../../layout';
import SEO from '../../components/Seo/Seo';
import PostCard from '../../components/PostCard/PostCard';
import PageOverview from './posts.style';

const Posts = props => {
  const allWordpressPost = props.data.allWordpressPost.edges;
  const datatLength = allWordpressPost.length;

  const [state, setState] = useState({
    visibile: 12,
  });

  const handleLoadMore = () => {
    setState(prev => ({ visibile: prev.visibile + 9 }));
  };

  const masonryColumns = {
    default: 4,
    1600: 3,
    991: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title="All nieuwsitems" />

      <PageOverview className="page-overview">
        <div className="overview-meta">
          <h3>Alle nieuwsitems</h3>
          <div className="post-count">{datatLength} resultaten</div>
        </div>

        <Masonry
          breakpointCols={masonryColumns}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {allWordpressPost.slice(0, state.visibile).map(post => (
            <Fade key={post.node.id} bottom>
              <PostCard
                date={post.node.date}
                title={post.node.title}
                excerpt={
                  post.node.childWordPressAcfIntro
                    ? post.node.childWordPressAcfIntro?.text
                    : post.node.childWordPressAcfParagraph?.text
                }
                path={post.node.path}
              />
            </Fade>
          ))}
        </Masonry>

        <div className="load-more-area">
          {state.visibile < datatLength ? (
            <button className="button secondary" onClick={handleLoadMore}>
              Laad meer nieuwsitems ({datatLength - state.visibile})
            </button>
          ) : (
            <p>No more nieuws items are left</p>
          )}
        </div>
      </PageOverview>
    </Layout>
  );
};

export default Posts;

export const pageQuery = graphql`
  {
    allWordpressPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          date(formatString: "DD MMMM YYYY", locale: "nl_NL")
          title
          path
          childWordPressAcfIntro {
            text
          }
          childWordPressAcfParagraph {
            text
          }
        }
      }
    }
  }
`;
